import React, { useRef } from "react"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import MenuIcon from "@material-ui/icons/Menu"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import NestedListItem from "../components/nested-list-item"
import ArticleNav from "../components/article-nav"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { ListItem, ListItemText } from "@material-ui/core"
import { useArticles, topicTitles } from "../hooks/articles"
import Logo from "./logo.svg"
import layoutStyles from "./layout.module.css"
import "fontsource-roboto"
const drawerWidth = 240

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#277da1",
    },
    secondary: {
      main: "#f94144",
    },
  },
  typography: {
    fontSize: 16,
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerLanding: {
    flexShrink: 0,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      zIndex: 1400,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hiddenMenuButton: {
    marginRight: theme.spacing(2),
    display: "none",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  articleLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },

  activeArticleLink: {
    color: theme.palette.primary.main,
  },
  logo: {
    width: 50,
    height: 50,
    "margin-right": 10,
  },
  toolbarContainer: {
    flex: 1,
  },
  toolbarTitleContainer: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
  },
  drawerTitle: {
    textAlign: "center",
  },
  drawerTopSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawerLink: {
    textDecoration: "none",
    color: "inherit",
  },
  listHeadingTop: {
    fontWeight: "bold",
  },
  listHeadingBottom: {
    fontWeight: "bold",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: "30px",
    marginTop: "15px",
  },
}))

export default function Layout(props) {
  const { window } = props.children
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const theRef = useRef()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const location = useLocation()
  const topicNameToArticlesMap = useArticles()

  const drawer = (
    <div>
      <Hidden smUp>
        <div classes={[classes.toolbar, classes.drawerTopSection]}>
          <a className={classes.drawerLink} href="/">
            <h3 className={classes.drawerTitle}>Explore Personal Finance</h3>
          </a>
        </div>
      </Hidden>
      <Hidden xsDown>
        <div className={classes.toolbar} />
      </Hidden>
      <Divider />
      <List component="nav">
        <ListItem className={classes.listHeadingTop}>Beginner Course</ListItem>
        {Object.keys(topicTitles).map(function (topic) {
          return (
            <React.Fragment>
              {topic === "retirement" && (
                <div>
                  <ListItem className={classes.listHeadingBottom}>
                    More Topics
                  </ListItem>
                </div>
              )}
              <NestedListItem
                title={topicTitles[topic]}
                nestedArticles={topicNameToArticlesMap[topic]}
                isExpanded={topicNameToArticlesMap[topic].reduce(
                  (isCurrentPage, article) => {
                    if (location.pathname === article.node.fields.slug) {
                      isCurrentPage = isCurrentPage || true
                    }
                    return isCurrentPage
                  },
                  false
                )}
              />
            </React.Fragment>
          )
        })}
      </List>
    </div>
  )
  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Hidden mdUp>
              <div className={classes.toolbarContainer}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className={classes.toolbarTitleContainer}>
                <a className={classes.drawerLink} href="/">
                  <img
                    className={classes.logo}
                    src={Logo}
                    alt="Logo of a map with a dollar sign"
                  />
                </a>
              </div>
              <div className={classes.toolbarContainer} />
            </Hidden>
            <Hidden smDown>
              {props.landing && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <a className={classes.drawerLink} href="/">
                <img
                  className={classes.logo}
                  src={Logo}
                  alt="Logo of a map with a dollar sign"
                />
              </a>
              <a className={classes.drawerLink} href="/">
                <Typography variant="h6" noWrap>
                  Explore Personal Finance
                </Typography>
              </a>
            </Hidden>
          </Toolbar>
        </AppBar>
        <nav className={props.landing ? classes.drawerLanding : classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          {!props.landing && (
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          )}
        </nav>
        <Grid
          container
          spacing={3}
          style={{ "padding-left": "12px", "padding-right": "12px" }}
        >
          <Grid item xs={0} sm={props.landing ? 2 : 1} />
          <Grid item xs={12} sm={props.landing ? 8 : 6} ref={theRef}>
            <div className={classes.toolbar} />
            {props.children}
          </Grid>
          {props.headings && (
            <Grid item xs={0} sm={2}>
              <Hidden xsDown>
                <div className={classes.toolbar} />
                <ArticleNav headings={props.headings} contentRef={theRef} />
              </Hidden>
            </Grid>
          )}
          <Grid item xs={0} sm={props.landing ? 2 : 0} />
        </Grid>
      </div>
    </MuiThemeProvider>
  )
}
