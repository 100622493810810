import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const topicTitles = {
  intro: "Introduction",
  assets: "Assets",
  liabilities: "Liabilities",
  strategy: "Using Your Money",
  retirement: "Retirement",
  fi: "Financial Independence",
  skills: "Money Skills",
  resources: "Resources",
}

export const useArticles = function () {
  const data = useStaticQuery(
    graphql`
      {
        allMdx {
          group(field: frontmatter___topic) {
            fieldValue
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  button
                  position
                  topic
                }
              }
            }
          }
        }
      }
    `
  )
  const topicNameToArticles = {}
  data.allMdx.group.map(function (group) {
    group.edges = group.edges.sort(
      (a, b) => a.node.frontmatter.position - b.node.frontmatter.position
    )
    topicNameToArticles[group.fieldValue] = group.edges
  })
  return topicNameToArticles
}

export const useAllArticlesFlat = function () {
  var topicNameToArticles = useArticles()
  var flatArticles = []
  Object.keys(topicTitles).map(topic => {
    flatArticles.push(...topicNameToArticles[topic])
  })
  return flatArticles
}
