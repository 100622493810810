import React, { useEffect, useState } from "react"
import Slugger from "github-slugger"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import useWindowScroll from "react-use/lib/useWindowScroll"
import useWindowSize from "react-use/lib/useWindowSize"

const useStyles = makeStyles(theme => ({
  ul: {
    padding: 0,
    margin: theme.spacing(1, 0, 0, 0),
    listStyleType: "none",
  },
  container: {
    marginTop: theme.spacing(3),
    position: "sticky",
    height: "calc(100vh - " + theme.spacing(3) + "px - 75px)",
    overflow: "auto",
    "&::-webkit-scrollbar": { height: 0, width: 0 },
    top: 75,
  },
  item: {
    padding: theme.spacing(0.5, 0, 0.5, 1),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    boxSizing: "content-box",
    "&:hover": {
      borderLeft: `4px solid ${
        theme.palette.type === "light"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark
      }`,
    },
    "&$active,&:active": {
      borderLeft: `4px solid ${
        theme.palette.type === "light"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark
      }`,
    },
  },
  activeItem: {
    padding: theme.spacing(0.5, 0, 0.5, 1),
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    boxSizing: "content-box",
    "&:hover": {
      borderLeft: `4px solid ${
        theme.palette.type === "light"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark
      }`,
    },
    "&$active,&:active": {
      borderLeft: `4px solid ${
        theme.palette.type === "light"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark
      }`,
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}))
const slugger = new Slugger()

export default function ArticleNav(props) {
  const classes = useStyles()
  const { y } = useWindowScroll()
  const { height } = useWindowSize()
  const [offsets, setOffsets] = useState([])

  useEffect(() => {
    const headings = props.contentRef.current.querySelectorAll(
      [
        1,
        ...props.headings
          .filter(heading => heading.depth === 2)
          .map(heading => heading.depth),
      ]
        .map(depth => "h" + depth)
        .toString()
    )
    setOffsets(
      Array.from(headings)
        .map(heading => {
          return {
            id: heading.id,
            offset: heading.offsetTop,
          }
        })
        .filter(Boolean)
    )
  }, [height, props.contentRef, props.headings])

  let activeHeading = null
  const scrollTop = y + 115
  for (let i = offsets.length - 1; i >= 0; i--) {
    const { id, offset } = offsets[i]
    if (scrollTop >= offset) {
      activeHeading = id
      break
    }
  }

  if (
    offsets.length > 0 &&
    y + height + 15 >= props.contentRef.current.clientHeight
  ) {
    activeHeading = offsets[offsets.length - 1].id
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <strong>Contents</strong>
        <ul className={classes.ul}>
          {props.headings
            .filter(heading => heading.depth === 2)
            .map(function (heading) {
              var slug = slugger.slug(heading.value)
              slugger.reset()
              var isActive = slug === activeHeading
              return (
                <li
                  key={heading.value}
                  className={isActive ? classes.activeItem : classes.item}
                >
                  <Link
                    to={"#" + slugger.slug(heading.value)}
                    className={classes.link}
                  >
                    {heading.value}
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>
      {slugger.reset()}
    </React.Fragment>
  )
}
