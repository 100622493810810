import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },

  articleLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },

  activeArticleLink: {
    color: theme.palette.primary.main,
  },
}))

export default function NestedListItem(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(props.isExpanded)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemText
          className={props.isExpanded ? classes.activeArticleLink : ""}
        >
          {props.title}
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.nestedArticles.map(edge => (
            <Link
              to={edge.node.fields.slug}
              className={classes.articleLink}
              activeClassName={classes.activeArticleLink}
            >
              <ListItem button className={classes.nested}>
                <ListItemText>{edge.node.frontmatter.title}</ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}
